import { Button, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../shared-components/navbar/Navbar";
import {
  ButtonBox,
  HeaderChild,
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
} from "./header.style";
import headerShape from "../../../assets/images/header-shape.png";
import headerImage from "../../../assets/images/header-image.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <HeaderContainer>
      <Navbar />
      <HeaderChild>
        <HeaderLeft>
          <Typography variant="h4">
            Get the Next <br/> Level Developments.{" "}
          </Typography>
          <Typography variant="body1">
            Every line of code we deliver is carefully crafted by our in-house
            team of professional developers. While constantly looking for new
            horizons, challenges, and opportunities in the web development, we
            have focused our attention on these core areas:
          </Typography>
          <ButtonBox>
            <Link to="/quote"><Button variant="contained">Get a Quote</Button></Link>
           <Link to="/call"> <Button variant="contained" className="call">Schedule a call</Button></Link>
          </ButtonBox>
        </HeaderLeft>
        <HeaderRight>
          <img src={headerImage} alt="header" loading="lazy" className="header-image" />
          <img src={headerShape} alt="header" loading="lazy" className="header-shape" />
        </HeaderRight>
      </HeaderChild>
     
      
    </HeaderContainer>
  );
};

export default Header;
