import styled from "styled-components";
import { Box } from "@mui/system";
import bg from "../../../assets/images/footer-bg.png";

export const FooterContainer = styled(Box)`
  width: 100%;
max-width: 120rem;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 1rem;
  .overlay {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 85%;
    height: 85%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  @media only screen and (max-width: 480px) {
    .overlay {
      top: 5%;
      left: 2.5%;
      width: 95%;
      height: 90%;
    }
  }
`;

export const FooterChild = styled(Box)`
  width: 100%;
  display: flex;
  padding: 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 786px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 480px) {
    padding: 1rem;
    justify-content: center;
    gap: 1rem;

    .fourth {
      justify-content: flex-start;
    }
  }
`;

export const FooterBox = styled(Box)`
  width: 22%;
  position: relative;

  &:last-of-type {
    width: 30%;
  }

  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #c4c4c4;
    margin-bottom: 0.6rem;

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid #00d285;
      }
    }
  }

  .logo {
    margin-top: 1.5rem;
    width: 170px;
    height: auto;
    align-self: flex-start;
  }

  @media only screen and (max-width: 786px) {
    width: 22%;
    &:last-of-type {
      width: 30%;
    }
  }

  @media only screen and (max-width: 480px) {
    width: 100%;

    &:last-of-type {
      width: 100%;
    }
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
    }

    .logo {
      margin-top: 1rem;
      width: 80px;
    }
  }
`;

export const FooterBoxChild = styled(Box)`
  width: 100%;
  display: flex;
`;

export const BoxChild = styled(Box)`
  display: block;

  &:last-of-type {
    margin-left: 1rem;
  }
`;

export const SocialBox = styled(Box)`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .social-icon {
    width: 25px;
    height: 25px;
    background: #000d2f;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 480px) {
    width: 40%;
    gap: 0.5rem;

    .social-icon {
      width: 20px;
      height: 20px;
      padding: 8px;
    }
  }
`;

export const ContactChild = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 0.5rem;




  p {
    margin: 0rem !important;
  }
  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  .MuiTypography-root.MuiTypography-body1 {
    flex-wrap: wrap;
    a {
      margin-left: 2px;
      color: #00d285;
      text-decoration: none;
      word-wrap: break-word;
    }
  }

  @media only screen and (max-width: 480px) {
    img {
      height: 16px;
      width: 16px;
    }
  }
`;

export const BackBox = styled(Box)`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: 2rem auto;
  cursor: pointer;

  img {
    width: 5%;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-top: 1rem;

    img {
      width: 10%;
    }
  }
`;

export const CopyrightBox = styled(Box)`
  width: 90%;
  margin: 0rem auto;
  z-index: 10;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #fff;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 10px;
    }
  }
`;
