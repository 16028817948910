import styled from "styled-components";
import { Box, AppBar, Toolbar } from "@mui/material";

export const MenuBox = styled(Box)`
  width: 100%;
  max-width: 120rem;
  min-height: 6rem;
  height: max-content;
  margin: 0 auto;
  padding: 0;
  top: 0;
  z-index: 5;
  box-shadow: none;
  background-color: ${({ isBlack }) => (isBlack ? "black" : "transparent")};

  .MuiPaper-root.MuiAppBar-root {
    background-color: inherit !important;
    box-shadow: none;
    padding: 0.5rem 1rem;
    /* position: fixed; */
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1240px) and (min-width: 900px) {
    width: 100%;
    .MuiPaper-root.MuiAppBar-root {
      padding: 0.5rem 2.5rem;
    }
  }
  @media only screen and (max-width: 899px) {
    .MuiPaper-root.MuiAppBar-root {
      padding: 0.5rem 1rem;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    .MuiPaper-root.MuiAppBar-root {
      padding: 0.5rem 1.5rem;
    }
  }
  @media only screen and (max-width: 480px) {
    height: 5rem;
    .MuiPaper-root.MuiAppBar-root {
      padding: 0.8rem 0 0;
      height: 5rem;
    }
  }
`;
export const MenuBar = styled(AppBar)`
  width: 100%;
  color: #000000;
`;

export const MenuToolBar = styled(Toolbar)`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-right: 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  .MuiTypography-root {
    color: #000000;
    @media only screen and(max-width: 480px) {
      font-size: 2.5rem;
    }
  }
  .MuiButtonBase-root.MuiButton-root {
    text-transform: none;
    color: #9e9e9e;
    font-weight: 600;
  }

  @media only screen and (max-width: 1240px) and (min-width: 900px) {
    padding: 0 !important;
  }

  @media only screen and (max-width: 899px) {
    .menu-div {
      display: flex;
      justify-content: flex-end;
      .MuiButton-root.MuiButton-text {
        text-transform: none;
        color: #00d285 !important;
        padding: 0;
        min-width: 15px;
        .MuiSvgIcon-root {
          color: ${({ isBlack }) =>
            isBlack ? "#00d285" : "#00d285"} !important;
        }
      }
    }
  }
  @media only screen and (max-width: 899px) and (min-width: 680px) {
    .menu-div {
      .MuiButton-root.MuiButton-text {
        text-transform: none;

        .MuiSvgIcon-root {
          color: ${({ isBlack }) => (isBlack ? "#00d285" : "#000")} !important;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    .menu-div {
      width: unset !important;
    }
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  @media only screen and (min-width: 480px) {
    padding: 0;
    padding-left: 0.8rem;
  }
  @media only screen and (max-width: 480px) {
    .menu-div {
      width: 15% !important;
    }
  }
`;
export const MenuLogoBox = styled(Box)`
  width: 39%;
  display: flex;
  justify-content: flex-start;

  a {
    width: 100%;
    text-decoration: none;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  img {
    width: 194px;
    height: 70.66px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: 600;
    color: #263238;
    font-size: 1.8rem;
  }
  @media only screen and (max-width: 1240px) and (min-width: 900px) {
    width: 22%;
    img {
      /* width: 100%;
      margin-left: 0; */
    }
  }
  @media only screen and (max-width: 899px) {
    width: 60%;
    justify-content: start;
    a {
      width: 70%;
      img {
        /* width: 100%; */
        /* margin-left: -6rem; */
      }
    }
  }
  @media only screen and (max-width: 768px) {
    a {
      img {
        /* margin-left: -3rem; */
      }
    }
  }
  @media only screen and (max-width: 480px) {
    width: 50%;
    a {
      width: 100%;
    }
  }
  @media only screen and (max-width: 360px) {
    width: 80%;
    a {
      img {
        /* margin-left: -2rem; */
      }
    }
  }
`;

export const ButtonBox = styled(Box)`
  width: 42%;
  display: flex;
  margin-right: 2.3rem;
  align-items: center;
  justify-content: space-between;
  a {
    color: white;
    text-decoration: none;
    .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
      letter-spacing: -0.015em;
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      &:first-of-type {
        @media only screen and (max-width: 960px) and(min-width: 900px) {
          margin-left: -6rem;
        }
      }
    }
    .active {
      color: #00d285 !important;
    }
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
    letter-spacing: 0px;
    color: #7f8da0;
    font-size: 1rem;
    font-weight: 400;
    MuiSvgIcon-root {
      color: #000000;
    }
    /* &:hover {
      border: 1px solid #ffffff;
    } */
  }
  @media only screen and (max-width: 1240px) and (min-width: 900px) {
    width: 49%;
  }
  @media only screen and (min-width: 900px) and (max-width: 940px) {
    margin-left: -4rem;
  }
`;