import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { Typography } from "@mui/material";
import "swiper/css";
import "swiper/scss/navigation";
import {
  Testimonials,
  TestimonialBoxA,
  TestimonialBoxB,
  TestimonialChild,
  TestimonialContainer,
  SubText,
  BoxAChild,
} from "./testimonial.style";
import testimonialShape from "../../../assets/images/testimonial-shape.png";
import clientA from "../../../assets/images/clientA.png";
import clientB from "../../../assets/images/clientB.png";
import clientC from "../../../assets/images/clientC.png";
import clientD from "../../../assets/images/clientD.jpg";
import clientE from "../../../assets/images/clientE.png";
import medeowloft from "../../../assets/images/meadowloft.png";
import glukogest from "../../../assets/images/glukogest.png";
import deftpress from "../../../assets/images/Deftpress2.png";
import servolution from "../../../assets/images/servolution.png";
import researchRuth from "../../../assets/images/researchReviewLogo.png";
import intAi from "../../../assets/images/intiailogo.png";
import havadoLogo from "../../../assets/images/havadocLogo.png";
import fxKara from "../../../assets/images/fxKara.png";
import which from "../../../assets/images/whinc.png";
// Reusable Testimonial Component Function
const TestimonialItem = ({ image, text, company }) => (
  <Testimonials className="testimonials">
    <img src={image} alt="testimony" className="testimony" />
    <Typography variant="body1">{text}</Typography>
    <SubText>
      <Typography variant="h6">{company}</Typography>
    </SubText>
  </Testimonials>
);

const Testimonial = () => {
  return (
    <TestimonialContainer>
      <TestimonialChild>
        <TestimonialBoxA>
          <BoxAChild>
            <div className="line"></div>
            <Typography variant="h6 header">TESTIMONIAL</Typography>
          </BoxAChild>
          <Typography variant="h5 subheader">
            Our clients span across various markets in Nigeria. See how some of
            them feel about us.
          </Typography>
        </TestimonialBoxA>
        <TestimonialBoxB>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            speed={1000}
            direction="vertical"
            autoplaySpeed={3000}
            navigation
          >
            <img src={testimonialShape} alt="testimonial" className="test" />
            <SwiperSlide>
              <TestimonialItem
                image={which}
                text="You did well. You never complained and kept it going until your client was happy. That is to be celebrated!"
                company="Modupe Elebute-Odunsi
Chairman/ Founder of WIHCN"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={researchRuth}
                text="Many thanks for sharing the link. We are very impressed with what we see so far."
                company="Yemisi Osakwe
Research Operations Lead, Research & Development
Marcelle Ruth Cancer Centre & Specialist Hospital"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={medeowloft}
                text="Thank you Peter. It's been a pleasure working with you."
                company="Ariyo Oluboye/Meadowloft"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={servolution}
                text="You know the project went on high speed. Faster than earlier expectations.  "
                company="Dominion Elshaddai, Founder, Servolution 
"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={havadoLogo}
                text="Petrong Software Solutions are legitimate, very industrous and creative company. They've served us very well as vendors and we have 
a good relationship with their founder Peter."
                company="Isaac Akinpelu, Founder, Havadoc"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={fxKara}
                text="What I like is that, Even without great documentation you interpreted it in a way whereby it meets my exact requirements. I thank God that I met you. Many thanks for all the work you did. "
                company="Oluwole Solanke, Founder, FxKarasell"
              />
            </SwiperSlide>
          </Swiper>
        </TestimonialBoxB>
      </TestimonialChild>
    </TestimonialContainer>
  );
};

export default Testimonial;
