import React from "react";
import {
  CoreBoxA,
  CoreBoxB,
  Corecontainer,
  CoreContainerChild,
  ServiceBox,
  TextBox,
} from "./core.style";
import coreImage from "../../../assets/images/core-image.jpg";
import coreImage2 from "../../../assets/images/guy-shows-document-girl-group-young-freelancers-office-have-conversation-working.jpg";
import coreImage3 from "../../../assets/images/teamHandsJoined.jpg";
import { Typography } from "@mui/material";
import ai from "../../../assets/images/aiDev.jpg";
import graphicsDesigner from "../../../assets/images/graphicsDesigner.jpg";
import cloud from "../../../assets/images/Cloud.png";
import Mobiledev from "../../../assets/images/Mobiledev.png";
import Maintenance from "../../../assets/images/Maintenance.png";
import onboarding from "../../../assets/images/onboarding.png";
import uiux from "../../../assets/images/uiux.png";
import blockChain from "../../../assets/images/blockchain.jpg";
import Web from "../../../assets/images/onboarding.png";

const Core = () => {
  return (
    <Corecontainer>
      <CoreContainerChild>
        <CoreBoxA>
          <img
            src={coreImage}
            alt="core"
            className="core-image"
            style={{ objectFit: "cover" }}
          />
          <img
            src={coreImage2}
            alt="core"
            className="core-image"
            style={{ marginTop: "20px", objectFit: "cover" }}
          />
          <img
            src={coreImage3}
            alt="core"
            className="core-image"
            style={{ marginTop: "20px", objectFit: "cover" }}
          />
        </CoreBoxA>
        <CoreBoxB>
          <Typography variant="h4">Our Core Services</Typography>
          <Typography variant="body1">
            Our technology solutions are perfectly designed to ensure the
            virtual world meets your needs.
          </Typography>
          {/* <ServiceBox>
            <img src={onboarding} alt="Onboard" className="" />

            <TextBox>
              <Typography variant="h6">
              Customer Onboarding for Microfinance Institutions
              </Typography>
              <Typography variant="body1">
              We know the ins and outs of Shopify & WooCommerce and we can
              help you get your eCommerce site running in a matter of weeks.
              </Typography>
            </TextBox>
          </ServiceBox> */}
          <ServiceBox>
            <img src={Web} alt="web" />
            <TextBox>
              {" "}
              <Typography variant="h6">Web Application Development</Typography>
              <Typography variant="body1">
                We deliver secure, high-performance, scalable, and SEO-friendly
                solutions with reusable components and responsive designs,
                perfect for websites, e-commerce, web apps, enterprise tools,
                and email marketing.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={Mobiledev} alt="mobile" className="mobile" />
            <TextBox>
              <Typography variant="h6">
                Mobile Application Development
              </Typography>
              <Typography variant="body1">
                We specialize in mobile app development, delivering
                customizable, easy-to-manage, and scalable solutions in a
                shorter time.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={cloud} alt="vue" className="vue" />
            <TextBox>
              <Typography variant="h6">
                Cloud server setup & configuration
              </Typography>
              <Typography variant="body1">
                We help you leverage cloud technology, creating agile,
                future-ready applications and using automation to boost
                efficiency and cut costs.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={Maintenance} alt="vue" className="vue" />
            <TextBox>
              <Typography variant="h6">
                Maintenance and Enhancement of existing software systems
              </Typography>
              <Typography variant="body1">
                Whether you have a complete solution or not, we help elevate
                your products with strategic enhancements, routine maintenance,
                and real-time support.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={uiux} alt="uiux" className="" />
            <TextBox>
              <Typography variant="h6">
                User Interface and User Experience Design
              </Typography>
              <Typography variant="body1">
                We transform your ideas into detailed UI/UX designs, enabling
                you to seamlessly navigate and interact with various prototyped
                features for an enhanced user experience.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={ai} alt="uiux" className="" />
            <TextBox>
              <Typography variant="h6">Artificial Intelligence</Typography>
              <Typography variant="body1">
                Leveraging cutting-edge technology and artificial intelligence
                to drive business growth, improve efficiency, and create
                sustainable solutions for a better tomorrow.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={graphicsDesigner} alt="graphics" className="" />
            <TextBox>
              <Typography variant="h6">Brand Design</Typography>
              <Typography variant="body1">
                Our team of skilled designers craft stunning visual solutions to
                communicate your message, capture attention, and inspire action
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={blockChain} alt="blockchain" className="" />
            <TextBox>
              <Typography variant="h6">Blockchain Development</Typography>
              <Typography variant="body1">
              Our team of expert developers design and implement secure, decentralized, and transparent blockchain solutions to transform your business and drive innovation.
              </Typography>
            </TextBox>
          </ServiceBox>
        </CoreBoxB>
      </CoreContainerChild>
    </Corecontainer>
  );
};
export default Core;
